import { EsriProvider, GoogleProvider } from 'leaflet-geosearch';
import {
  GOOGLE_PLACES_API_KEY,
  ADDRESS_AUTOCOMPLETE_PROVIDER,
  NODE_ENV,
} from '~/constants';

const getAllowedCountries = () => {
  return NODE_ENV === 'development'
    ? ['CA', 'PT'] // Canada and Portugal
    : ['CA']; // Canada only
};

const getProvider = () => {
  const allowedCountries = getAllowedCountries();

  if (ADDRESS_AUTOCOMPLETE_PROVIDER === 'esri') {
    return new EsriProvider({
      params: {
        sourceCountry: allowedCountries.join(','),
      },
    });
  }

  return new GoogleProvider({
    apiKey: GOOGLE_PLACES_API_KEY,
    params: {
      components: allowedCountries
        .map((country) => `country:${country}`)
        .join('|'),
    },
  });
};

export const provider = getProvider();

export interface IGeocodingResult {
  x: number; // lon,
  y: number; // lat,
  label: string; // formatted address
  bounds:
    | [
        [number, number], // s, w - lat, lon
        [number, number], // n, e - lat, lon
      ]
    | null;
  raw: {
    name: string;
  };
}

export async function geocode(address: string): Promise<IGeocodingResult[]> {
  const results = await provider.search({ query: address });
  return results;
}
